import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/form/form"

const Contact = () => (
	<Layout>
		<SEO title="Contact Us" description="Contact Page" />
		<div className="page-hero contact-page">
			<div className="hero-content">
				<h5 data-aos="fade" data-aos-duration="300" data-aos-delay="900">
					INTERMOUNTAIN BOILERWORX INC.
				</h5>
				<h1
					data-aos="fade-up"
					data-aos-duration="600"
					data-aos-delay="1200"
					className="font-weight-bolder mt-3 mt-md-5"
				>
					Contact Us
				</h1>
			</div>
		</div>
		<div className="services">
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div className="call-a-tech_content text-bg-orange">
							<h2 className="text-white">
								We welcome the opportunity to earn your trust and deliver you
								the best service in the industry.
							</h2>
							<p className="text-white">
								Please fill out the form to be contacted by one of our
								employees, feel free to add details of information to the
								comment box.
							</p>
							<p className="text-white">
								If you want to contact us through other means, use the details
								below.
							</p>
							<ul className="text-white contact-details">
								<li>
									<i class="fa fa-envelope" />
									&nbsp; P.O. Box 270971
								</li>
								<li>
									<i class="fa fa-map-marker" />
									&nbsp;Littleton, CO. 80127
								</li>
								<li>
									<i class="fa fa-phone" />
									&nbsp;(303) 845-0432 (24 hours)
								</li>
								<li>
									<i class="fa fa-fax" />
									&nbsp;(720) 981-8868 (fax)
								</li>
								<li>
									<i class="fa fa-address-card" />
									&nbsp;dan@iboilerworx.com
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md-6">
						{" "}
						<div className="contact-clean">
							<ContactForm
								data-form_id="5ea1843e1ee3844f5417c70a"
								form_name="Contact Form"
								form_classname="contact-form"
								method="POST"
							>
								{/* <h2 className="text-center"></h2> */}
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="FirstName"
										id="fname"
										placeholder="First Name"
										aria-label="fname"
										required
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="LastName"
										id="lname"
										placeholder="Last Name"
										aria-label="lname"
										required
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="Company"
										id="company"
										placeholder="Company"
										aria-label="company"
									/>
								</div>

								<div className="form-group">
									<input
										className="form-control is-invalid"
										type="email"
										name="Email"
										id="email"
										placeholder="Email"
										aria-label="email"
										required
									/>
									<small className="form-text text-danger">
										Please enter a correct email address.
									</small>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="phone"
										name="Phone"
										id="phone"
										placeholder="Phone"
										aria-label="phone"
										maxlength="15"
										required
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="Address1"
										id="address1"
										placeholder="Address 1"
										aria-label="address1"
										required
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="Address2"
										id="address2"
										placeholder="Address 2"
										aria-label="address2"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="City"
										id="city"
										placeholder="City"
										aria-label="city"
										required
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="State"
										id="state"
										placeholder="State"
										aria-label="state"
										maxlength="30"
										required
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="Zip"
										id="zip"
										placeholder="Zip"
										aria-label="zip"
										maxlength="15"
										required
									/>
								</div>
								<div className="form-group">
									<textarea
										className="form-control"
										name="Message"
										id="message"
										placeholder="Comments"
										aria-label="message"
										rows="14"
										required
									/>
								</div>
								<div className="form-group mb-0">
									<div className="webriq-recaptcha" />
								</div>
								<div className="form-group">
									<div className="button-container h-margin text-center">
										<button className="btn-secondary" type="submit">
											<span>
												<strong>SEND MESSAGE</strong>
												<br />
											</span>
										</button>
									</div>
								</div>
							</ContactForm>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Contact
